<template>
  <BaseForm :on-success="save">
    <slot :cancel="cancel" :itemIsNew="itemIsNew" />
  </BaseForm>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
import BaseForm from '@/components/forms/BaseForm'

export default {
  components: {
    BaseForm,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('schoolLists/categories', ['itemIsNew']),
  },
  methods: {
    ...mapMutations('schoolLists/categories', ['SET_ITEM_EDIT_STATE']),
    cancel() {
      if (this.itemIsNew(this.item)) {
        this.$store.dispatch('schoolLists/categories/removeItem', {
          category: this.category,
          item: this.item,
        })
      } else {
        this.SET_ITEM_EDIT_STATE({
          item: this.item,
          editState: false,
        })
      }
    },
    async save() {
      await this.$store.dispatch('schoolLists/categories/addItemToCategory', {
        item: this.item,
        category: this.category,
      })
    },
  },
}
</script>
